import { graphql, navigate } from 'gatsby';
import React from 'react';
import * as i from 'types';

import { Page, Template } from 'common/layout';
import {
  C02Equivalent,
  ResultCarsTable,
  ResultCta,
  ResultEmissionsSection,
  ResultFilterTab,
  ResultHeader,
  ResultInfraTable,
  ResultTotalTable,
  ResultUsefulBlogs,
} from 'modules/results';
import { useQueryUser } from 'queries/users';
import {
  calculateAllCosts,
  calculateOutcome,
  contentSelector,
  getTotalSavedEmissions,
  getTotalSavedEmissionsPercentage,
} from 'services';

import CarImage from 'images/car.webp';
import VanImage from 'images/van.webp';

const PersonalResultPage = ({ params, data }: PersonalResultPageProps) => {
  const cars = contentSelector(
    data.allContentfulCars,
  ) as GatsbyTypes.ContentfulCars[];
  const chargingStationData = contentSelector(
    data.allContentfulChargingStations,
  ) as i.ChargingStation[];
  const variables = contentSelector(
    data.allContentfulVariables,
  )[0] as GatsbyTypes.ContentfulVariables;
  const blogs = contentSelector(
    data.allContentfulComponentBlog,
  )[0] as GatsbyTypes.ContentfulComponentBlog;

  const userId = params.id;
  const { data: user } = useQueryUser(userId);
  const [period, setPeriod] = React.useState<i.Period>('year');
  const answers = user?.answers as i.ResultAnswers;
  const costs = React.useMemo(
    () => calculateAllCosts(cars, variables, period, answers),
    [answers, period],
  );
  const outcome = calculateOutcome(
    costs.total.ev.total,
    costs.total.petrol.total,
  );
  const totalSavedEmissions = getTotalSavedEmissions(costs);
  const totalSavedEmissionsPercentage = getTotalSavedEmissionsPercentage(costs);

  React.useEffect(() => {
    if (answers && !answers.fleet) {
      navigate('/result/niet-gevonden');
    }
  }, [answers]);

  if (!user || !answers.fleet) return null;

  return (
    <Template>
      <Page useSimpleMenu>
        <ResultHeader
          costs={costs.total}
          allCosts={costs}
          totalSavedEmissions={totalSavedEmissions}
          totalSavedEmissionsPercentage={totalSavedEmissionsPercentage}
          financeType={answers.financing}
          {...{ variables, period, answers, outcome }}
        />
        {/* Anchor for the scroll to button in the page header */}
        <div id="carsAnchor" />
        <ResultFilterTab {...{ period, setPeriod }} />
        <ResultCarsTable
          {...{ answers, cars, costs, period, setPeriod, outcome }}
        />
        {answers.userType === 'consumer' && <ResultUsefulBlogs data={blogs} />}
        {(answers?.chargingStation === 'yes' ||
          answers?.chargingStation === 'yes-home' ||
          answers?.solarPanels === 'no-yes') && (
          <ResultInfraTable
            {...{ answers, infra: chargingStationData, costs }}
          />
        )}
        <ResultTotalTable
          type="costs"
          allCosts={costs}
          {...{ period, answers, cars }}
        />
        <ResultCta image={VanImage} answers={answers} />
        <ResultEmissionsSection>
          <ResultTotalTable
            type="emissions"
            allCosts={costs}
            {...{ period, answers, cars }}
          />
          <C02Equivalent
            answers={answers}
            emissionDifference={totalSavedEmissions}
          />
          <ResultCta image={CarImage} answers={answers} />
        </ResultEmissionsSection>
      </Page>
    </Template>
  );
};

type PersonalResultPageProps = {
  params: {
    id: string;
  };
  data: {
    allContentfulCars: GatsbyTypes.ContentfulCarsConnection;
    allContentfulVariables: GatsbyTypes.ContentfulVariablesConnection;
    allContentfulChargingStations: GatsbyTypes.ContentfulChargingStationsConnection;
    allContentfulComponentBlog: GatsbyTypes.ContentfulComponentBlogConnection;
  };
};

export const query = graphql`
  query PersonalResultPage {
    allContentfulCars {
      edges {
        node {
          contentful_id
          id
          typeOfFlow
          order
          shouldBeInTcoTool
          brand
          model
          description
          size
          price
          financialLeasePriceMonthlyBusiness
          operationalLeasePriceMonthlyBusiness
          insuranceCostsYearlyBusiness
          privateLeasePriceMonthlyConsumers
          financialLeasePriceMonthlyConsumers
          insuranceCostsYearlyConsumers
          seppSubsidyConsumers
          maintenanceCostsYearly
          taxesCostsYearly
          type
          image {
            file {
              url
            }
          }
          fixedEmissions
          emissionsPerKm
          comparison
        }
      }
    }
    allContentfulChargingStations {
      edges {
        node {
          id
          brand
          image {
            file {
              url
            }
          }
          model
          infraType
          isInTcoTool
          price
          loadingCapacity
          priceIncludingAssembly
          summaryUsps {
            usp
          }
        }
      }
    }
    allContentfulVariables {
      edges {
        node {
          infrastructureHigh
          infrastructureLow
          subsidyPercentagePerCar
          maxSubsidyAmountPerCar
          taxBenefitPassengerCar
          taxBenefitVan
          maxAmountOfCarsForTaxBenefit
          taxBenefitInfrastructureHome
          taxBenefitInfrastructureZzp
          taxBenefitInfrastructureBusiness
          taxBenefitNettoPercentage
          energyCostsCityRegularCharging
          energyCostsHighwayRegularCharging
          energyCostsCityPublicCharging
          energyCostsHighwayPublicCharging
          petrolCostsCity
          petrolCostsHighway
          outcomeTotalFavorableTitle {
            outcomeTotalFavorableTitle
          }
          outcomeTotalFavorableText {
            outcomeTotalFavorableText
          }
          outcomeTotalFavorableLeaseText {
            outcomeTotalFavorableLeaseText
          }
          outcomeTotalNeutralText {
            outcomeTotalNeutralText
          }
          outcomeTotalNeutralTitle {
            outcomeTotalNeutralTitle
          }
          outcomeTotalNotFavorableText {
            outcomeTotalNotFavorableText
          }
          outcomeTotalNotFavorableTitle {
            outcomeTotalNotFavorableTitle
          }
          personenautoCostMultiplier
          kleineBestelwagenCostMultiplier
          middelBestelwagenCostMultiplier
          grootBestelwagenCostMultiplier
          emissionKmEvPassenger
          emissionKmEvLarge
          emissionKmEvMedium
          emissionKmEvSmall
          emissionKmPetPassenger
          emissionKmPetLarge
          emissionKmPetMedium
          emissionKmPetSmall
          emissionOutcomeFavorable {
            emissionOutcomeFavorable
          }
          emissionOutcomeNotFavorable {
            emissionOutcomeNotFavorable
          }
          emissionResultsTooltip {
            emissionResultsTooltip
          }
        }
      }
    }
    allContentfulComponentBlog(
      filter: { internalName: { eq: "Handige links" } }
    ) {
      edges {
        node {
          internalName
          id
          title
          items {
            id
            title
            subtitle
            url
          }
        }
      }
    }
  }
`;

export default PersonalResultPage;
