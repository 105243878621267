import * as React from 'react';
import * as i from 'types';

import { Paragraph } from 'common/typography';
import { useScrollTo } from 'hooks';
import ArrowBoxIcon from 'vectors/icons/arrow-box.svg';
import ArrowDownIcon from 'vectors/icons/arrow-down.svg';

import { Button } from 'common/interaction';
import { ResultSummaryCard } from '../ResultSummaryCard';
import { SummaryCardItem } from './components/SummaryCardItem';
import {
  HeadingButtonsContainer,
  ResultSummaryContainer,
  ResultSummaryTitle,
  SummaryCardContent,
} from './styled';

export const ResultSummary: React.FC<ResultSummaryProps> = ({
  outcomeText,
  costsDifference,
  period,
  paybackPeriod,
  totalSavedEmissions,
  totalSavedEmissionsPercentage,
  answers,
  numberOfCars,
  headerTooltip,
}) => {
  const { scrollTo } = useScrollTo();
  const isConsumerFlow = answers?.userType === 'consumer';
  const ctaUrl = isConsumerFlow
    ? 'https://www.nn.nl/Over-NationaleNederlanden/Duurzame-samenleving/Klimaat.htm'
    : 'https://www.nn.nl/Zakelijk/Duurzaamheid.htm';
  const summaryCardTitle =
    isConsumerFlow && numberOfCars > 1
      ? 'Per jaar (gemiddeld per categorie)'
      : 'Per jaar';
  const savedEmissions = isConsumerFlow
    ? totalSavedEmissions / numberOfCars
    : totalSavedEmissions;

  return (
    <ResultSummaryContainer>
      <div>
        <ResultSummaryTitle width={544} variant={'orange'}>
          {outcomeText?.title}
        </ResultSummaryTitle>
        <Paragraph noMargin>
          Lees meer over het totale kostenplaatje en je CO2-besparing.
          <br />
          Let op: dit is een indicatie.
        </Paragraph>
        <HeadingButtonsContainer>
          <Button onClick={() => scrollTo('#carsAnchor', 110)}>
            Uitleg berekening <ArrowDownIcon />
          </Button>
          <Button variant="secondary" href={ctaUrl} urlTarget="blank">
            Lees meer over duurzame mobiliteit <ArrowBoxIcon />
          </Button>
        </HeadingButtonsContainer>
      </div>

      <ResultSummaryCard title={summaryCardTitle}>
        <SummaryCardContent>
          <SummaryCardItem {...{ costsDifference }} />
          <SummaryCardItem
            isEmissionsItem
            {...{
              savedEmissions,
              totalSavedEmissionsPercentage,
              period,
              paybackPeriod,
              headerTooltip,
            }}
          />
        </SummaryCardContent>
      </ResultSummaryCard>
    </ResultSummaryContainer>
  );
};

type ResultSummaryProps = {
  outcomeText: {
    title?: string;
    text?: string;
  } | null;
  costsDifference: number;
  totalSavedEmissions: number;
  totalSavedEmissionsPercentage: number;
  period: i.Period;
  paybackPeriod?: number;
  outcome: string;
  answers: i.Answers;
  numberOfCars: number;
  headerTooltip?: {
    title: string;
  };
};
