import React from 'react';
import * as i from 'types';

import { ResultSummary } from 'modules/results';
import {
  calculateDifference,
  calculatePaybackPeriod,
  totalOutcomeTextSelector,
} from 'services';

export const ResultHeader = ({
  costs,
  variables,
  period,
  financeType,
  allCosts,
  answers,
  outcome,
  totalSavedEmissions,
  totalSavedEmissionsPercentage,
}: ResultHeaderProps) => {
  // Add yearly costs to a separate state because we don't want to change the yearly costs in the
  // summary when you click "Per month" in the filters
  const [yearlyCostsDifference, setYearlyCostsDifference] = React.useState<
    number | undefined
  >(undefined);
  const costsDifference = calculateDifference(
    costs.ev.totalMinusSubsidiesAndTaxes,
    costs.petrol.totalMinusSubsidiesAndTaxes,
  );
  const paybackPeriod = calculatePaybackPeriod(allCosts, costsDifference);
  const outcomeText = totalOutcomeTextSelector(outcome, variables, financeType);
  const numberOfCars = answers.fleet ? Object.keys(answers.fleet).length : 0;

  React.useEffect(() => {
    if (answers.userType === 'consumer') {
      return setYearlyCostsDifference(costsDifference / numberOfCars);
    }

    setYearlyCostsDifference(costsDifference);
  }, []);

  return (
    <ResultSummary
      costsDifference={yearlyCostsDifference || 0}
      {...{
        outcomeText,
        totalSavedEmissions,
        totalSavedEmissionsPercentage,
        period,
        paybackPeriod,
        allCosts,
        answers,
        outcome,
        numberOfCars,
        headerTooltip: {
          title: variables.emissionResultsTooltip?.emissionResultsTooltip || '',
        },
      }}
    />
  );
};

type ResultHeaderProps = {
  costs: i.CostsOverview;
  allCosts: i.AllCostsType;
  variables: GatsbyTypes.ContentfulVariables;
  totalSavedEmissions: number;
  totalSavedEmissionsPercentage: number;
  period: i.Period;
  financeType: i.FinanceType;
  answers: i.Answers;
  outcome: string;
};
