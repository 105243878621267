import * as i from 'types';
import styled, { css } from 'styled-components';

import { Paragraph } from 'common/typography';
import { getColor, media } from 'styles/utils';

export const Row = styled.div<RowProps>`
  display: flex;
  flex-wrap: ${({ noWrap }) => (noWrap ? 'nowrap' : 'wrap')};
  align-items: center;
  margin: 0px;
  border-top: 2px solid ${({ theme }) => theme.colors.gray.background};
  position: relative;
  padding: 24px 16px;

  ${({ $isConsumerCarsTable }) =>
    !$isConsumerCarsTable &&
    css`
      ${media.desktop`
        flex-wrap: nowrap;
        margin: 0 32px;
        padding: 20px 0;
      `}
    `}

  span {
    font-weight: ${({ subtitleWeight }) => subtitleWeight || 100};
    font-size: ${({ subtitleSize }) => subtitleSize || 14}px;
    line-height: 24px;
  }
`;

type RowProps = {
  noWrap?: boolean;
  subtitleSize?: number;
  subtitleWeight?: number;
  $isConsumerCarsTable?: boolean;
};

export const TitleWrapper = styled.div<TitleWrapperProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 16px;

  ${({ $isConsumerCarsTable }) =>
    !$isConsumerCarsTable &&
    css`
      ${media.desktop`
        width: 372px;
      `}
    `}
`;

type TitleWrapperProps = {
  $isConsumerCarsTable?: boolean;
};

export const Title = styled(Paragraph)<TitleProps>`
  display: block;
  grid-gap: 8px;
  justify-content: start;
  width: 100%;
  margin: 0;
  margin-right: auto;
  line-height: 22px;
  font-size: ${({ size }) => size || 16}px;

  & > span {
    display: ${({ $alwaysShowSubtitle }) =>
      $alwaysShowSubtitle ? 'block' : 'none'};
  }

  ${({ $isConsumerCarsTable }) =>
    !$isConsumerCarsTable &&
    css`
      ${media.desktop`
        min-width: 250px;
        max-width: 360px;
        margin: 0 auto 0 0;

        & > span {
           display: block;
          }
       `}
    `}
`;

type TitleProps = {
  $alwaysShowSubtitle?: boolean;
  $isConsumerCarsTable?: boolean;
};

export const RowItemsList = styled.ul`
  display: flex;
  width: 100%;
  padding: 0;
  gap: 8px;
`;

export const RowItem = styled.li<RowItemProps>`
  width: ${({ hasHighlight, noWrap }) =>
    hasHighlight ? '100%' : noWrap ? '150px' : 'auto'};
  justify-content: ${({ noWrap }) => (noWrap ? 'flex-end' : 'flex-start')};
  margin: 0px auto 0 0;
  display: flex;
  align-items: flex-start;
  width: 50%;
  gap: 8px;
  font-size: 14px;
  font-weight: ${({ weight }) => weight || 400};
  color: ${({ variant, theme }) => getColor(theme, variant || 'black')};

  ${({ $isConsumerCarsTable }) =>
    !$isConsumerCarsTable &&
    css`
      ${media.desktop`
        align-items: center;
        width: 100%;
        margin: 0;
        font-size: 16px;
        justify-content: flex-end;
      `}
    `}

  svg {
    height: 16px;
    width: 16px;
    flex-shrink: 0;

    ${({ $isConsumerCarsTable }) =>
      !$isConsumerCarsTable &&
      css`
        ${media.desktop`
          display: none;
         `}
      `}
  }
`;

type RowItemProps = {
  noWrap?: boolean;
  hasHighlight?: boolean;
  weight?: number;
  variant?: i.TextColors;
  $isConsumerCarsTable?: boolean;
};

export const RowItemResult = styled.div<RowItemResultProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${({ $isConsumerCarsTable }) =>
    !$isConsumerCarsTable &&
    css`
      ${media.desktop`
        flex-direction: row;
        flex-direction: row-reverse;
        align-items: center;
       `}
    `}
`;

type RowItemResultProps = {
  $isConsumerCarsTable?: boolean;
};

export const RowItemResultTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const RowItemResultText = styled.div<RowItemResultTextProps>`
  ${({ variant }) =>
    variant === 'green' &&
    css`
      padding: 4px;
      background-color: ${({ theme }) => theme.colors.green['100']};
      border-radius: 4px;
    `}
`;

type RowItemResultTextProps = {
  variant?: i.TextColors;
};
