import * as i from 'types';
import * as React from 'react';

import { ResultCarCardConsumer } from 'modules/results/ResultCarCardConsumer';
import { ResultCarsExpandedTable } from 'modules/results/ResultCarsExpandedTable';
import { carCostsRows, carEmissionsRows } from 'modules/results/ResultRows';
import { calcSavings, carSelector, formatPrice } from 'services';
import EuroIcon from 'vectors/icons/euro-orange.svg';
import { ResultCarsTableConsumerContainer } from './styled';

export const ResultCarsTableConsumer = ({
  period,
  answers,
  cars,
  costs,
  selectedEvFleet,
}: ResultCarsTableConsumerProps) => {
  return (
    <ResultCarsTableConsumerContainer>
      {selectedEvFleet.map((evCar) => {
        const carComparison = evCar.comparison as i.KeyOfFleetInput;
        const petrolCar = carSelector(cars, carComparison, 'petrol', answers);
        const timeCopy = period === 'year' ? 'per jaar' : 'per maand';
        const costsRows = carCostsRows(costs, timeCopy, carComparison, answers);
        const emissionsRows = carEmissionsRows(
          costs,
          period,
          carComparison,
          answers,
        );
        const savings = calcSavings(costs, carComparison);
        const showHighlight = savings.costsSavingsPercentage > 0;

        return (
          <ResultCarCardConsumer
            key={carComparison}
            image={evCar?.image}
            showHighlight={showHighlight}
            header={{
              title: evCar?.description,
            }}
            rows={[
              {
                title: `Kosten ${timeCopy}`,
                value: `${formatPrice(
                  costs.totalIndiv.evPrice[carComparison],
                )}`,
                highlight: {
                  icon: <EuroIcon />,
                  text: `${savings.costsSavingsPercentage}% minder kosten`,
                },
              },
            ]}
          >
            <ResultCarsExpandedTable
              {...{
                costsRows,
                emissionsRows,
                evCar,
                petrolCar,
                answers,
              }}
              $isConsumerCarsTable
            />
          </ResultCarCardConsumer>
        );
      })}
    </ResultCarsTableConsumerContainer>
  );
};

type ResultCarsTableConsumerProps = {
  answers: i.Answers;
  cars: GatsbyTypes.ContentfulCars[];
  costs: i.AllCostsType;
  period: i.Period;
  setPeriod: i.SetState;
  outcome: string;
  selectedEvFleet: GatsbyTypes.ContentfulCars[];
};
