import * as i from 'types';

import { formatPrice, getTimeUnit } from 'services';
import Diesel from 'vectors/icons/diesel.svg';
import Lightning from 'vectors/icons/lightning.svg';

export const totalCostsConsumer = (
  allCosts: i.AllCostsType,

  carComparison: keyof i.CarInput | keyof i.VanInput,
  answers: i.Answers,
  period: i.Period,
): i.ResultsCarTableRowType[] => {
  const timeUnit = getTimeUnit(period);
  const multiplier = period === 'month' ? 12 : 1;

  const oneOffPetrol = allCosts.fleetIndiv.petrolPrice[carComparison];
  const oneOffEv =
    allCosts.fleetIndiv.evPrice[carComparison] +
    allCosts.infra +
    allCosts.solarPanels.oneOffCosts -
    allCosts.taxBenefitIndiv[carComparison];
  const fixedPetrol =
    allCosts.leaseIndiv.petrolPrice[carComparison] +
    allCosts.petrolTaxIndiv[carComparison] +
    allCosts.insuranceIndiv.petrolPrice[carComparison] +
    allCosts.maintenanceIndiv.petrolPrice[carComparison];
  const fixedEv =
    allCosts.leaseIndiv.evPrice[carComparison] +
    allCosts.insuranceIndiv.evPrice[carComparison] +
    allCosts.maintenanceIndiv.evPrice[carComparison];
  const variablePetrol = allCosts.variable.rangeAmount.petrol[carComparison];
  const variableEv = allCosts.variable.rangeAmount.ev[carComparison];

  const totalPetrol =
    oneOffPetrol / parseInt(answers.depreciation!) / multiplier +
    fixedPetrol +
    variablePetrol;
  const totalEv =
    oneOffEv / parseInt(answers.depreciation!) / multiplier +
    fixedEv +
    variableEv;

  const carData: i.ResultsCarTableRowType[] = [
    {
      title: {
        text: `Netto investering (${timeUnit})`,
        variant: 'black',
        subtitle: 'Aanschafkosten auto en infrastructuur minus subsidie',
        weight: 400,
      },
      items: [
        {
          text: formatPrice(
            oneOffPetrol / parseInt(answers.depreciation!) / multiplier,
          ),
          highlight:
            oneOffPetrol === 0
              ? undefined
              : `In ${answers.depreciation} jaar ${formatPrice(oneOffPetrol)}`,
          variant: 'black',
          icon: Diesel,
        },
        {
          text: formatPrice(
            oneOffEv / parseInt(answers.depreciation!) / multiplier,
          ),
          highlight: `In ${answers.depreciation} jaar ${formatPrice(oneOffEv)}`,
          variant: 'black',
          icon: Lightning,
        },
      ],
    },
    {
      title: {
        text: `Vaste kosten (${timeUnit})`,
        variant: 'black',
        subtitle:
          'Private / financial lease, motorrijtuigenbelasting, verzekeringen en onderhoud',
        weight: 400,
      },
      items: [
        {
          text: `${formatPrice(fixedPetrol)}`,
          variant: 'black',
          icon: Diesel,
        },
        {
          text: `${formatPrice(fixedEv)}`,
          variant: 'black',
          icon: Lightning,
        },
      ],
    },
    {
      title: {
        text: `Variabele kosten (${timeUnit})`,
        variant: 'black',
        subtitle: 'Laad- of benzinekosten',
        weight: 400,
      },
      items: [
        {
          text: `${formatPrice(variablePetrol)}`,
          variant: 'black',
          icon: Diesel,
        },
        {
          text: `${formatPrice(variableEv)}`,
          variant: 'black',
          icon: Lightning,
        },
      ],
    },
    {
      title: {
        text: `Totale kosten ${timeUnit}`,
        variant: 'black',
        weight: 400,
      },
      items: [
        {
          text: `${formatPrice(totalPetrol)}`,
          variant: 'black',
          weight: 700,
          icon: Diesel,
        },
        {
          text: `${formatPrice(totalEv)}`,
          variant: 'orange',
          weight: 700,
          icon: Lightning,
        },
      ],
    },
  ];

  return carData;
};
