import * as i from 'types';

import { formatPrice } from 'services';
import Diesel from 'vectors/icons/diesel.svg';
import Lightning from 'vectors/icons/lightning.svg';

export const carCostsRows = (
  allCosts: i.AllCostsType,
  timeCopy: string,
  carComparison: keyof i.CarInput | keyof i.VanInput,
  answers: i.Answers,
): i.ResultsCarTableRowType[] => {
  const depreciationTime = !answers?.depreciation
    ? 5
    : parseInt(answers?.depreciation);

  const carPrice: i.ResultsCarTableRowType = {
    title: {
      text: 'Aanschafkosten',
      variant: 'black',
    },
    items: [
      {
        text: `${
          timeCopy === 'per jaar'
            ? formatPrice(
                allCosts.fleetIndiv.petrolPrice[carComparison] /
                  depreciationTime,
              )
            : formatPrice(
                allCosts.fleetIndiv.petrolPrice[carComparison] /
                  depreciationTime /
                  12,
              )
        }`,
        highlight: `In ${depreciationTime} jaar ${formatPrice(
          allCosts.fleetIndiv.petrolPrice[carComparison],
        )} `,
        icon: Diesel,
      },
      {
        text: `${
          timeCopy === 'per jaar'
            ? formatPrice(
                allCosts.fleetIndiv.evPrice[carComparison] / depreciationTime,
              )
            : formatPrice(
                allCosts.fleetIndiv.evPrice[carComparison] /
                  depreciationTime /
                  12,
              )
        }`,
        highlight: `In ${depreciationTime} jaar ${formatPrice(
          allCosts.fleetIndiv.evPrice[carComparison],
        )}`,
        icon: Lightning,
      },
    ],
  };

  // not for operational lease
  const miaTaxBenefit: i.ResultsCarTableRowType = {
    title: {
      text: 'Netto belastingvoordeel MIA',
      variant: 'black',
      subtitle: `Gehele subsidie berekend over ${answers.depreciation} jaar`,
    },
    items: [
      {
        text: 'N.v.t.',
        variant: 'black',
        icon: Diesel,
      },
      {
        text: `- ${formatPrice(allCosts.taxBenefitIndiv[carComparison])}`,
        variant: 'green',
        icon: Lightning,
      },
    ],
  };

  let leasePriceTitle = '';
  if (answers.financing === 'financial') {
    leasePriceTitle = 'Financial';
  }
  if (answers.financing === 'operational' && answers.userType === 'business') {
    leasePriceTitle = 'Operational';
  }
  if (answers.financing === 'operational' && answers.userType === 'consumer') {
    leasePriceTitle = 'Private';
  }

  const leasePrice: i.ResultsCarTableRowType = {
    title: {
      text: `${leasePriceTitle} lease`,
      variant: 'black',
    },
    items: [
      {
        text: formatPrice(allCosts.leaseIndiv.petrolPrice[carComparison]),
        icon: Diesel,
      },
      {
        text: formatPrice(allCosts.leaseIndiv.evPrice[carComparison]),
        icon: Lightning,
      },
    ],
  };

  const carData: i.ResultsCarTableRowType[] = [
    {
      title: {
        text: `Autoverzekering (${timeCopy})`,
        variant: 'black',
      },
      items: [
        {
          text: `${
            answers.financing === 'operational'
              ? 'Reeds verwerkt in leasebedrag'
              : formatPrice(allCosts.insuranceIndiv.petrolPrice[carComparison])
          }`,
          variant: 'black',
          icon: Diesel,
        },
        {
          text: `${
            answers.financing === 'operational'
              ? 'Reeds verwerkt in leasebedrag'
              : formatPrice(allCosts.insuranceIndiv.evPrice[carComparison])
          }`,
          variant: 'black',
          icon: Lightning,
        },
      ],
    },
    {
      title: {
        text: `Motorrijtuigenbelasting (${timeCopy})`,
        variant: 'black',
      },
      items: [
        {
          text: `${
            answers.financing === 'operational'
              ? 'Reeds verwerkt in leasebedrag'
              : formatPrice(allCosts.petrolTaxIndiv[carComparison])
          }`,
          variant: 'black',
          icon: Diesel,
        },
        {
          text: 'Geen',
          variant: 'black',
          icon: Lightning,
        },
      ],
    },
    {
      title: {
        text: `Onderhoud (${timeCopy})`,
        variant: 'black',
      },
      items: [
        {
          text:
            answers.financing === 'operational'
              ? 'Reeds verwerkt in leasebedrag'
              : allCosts.maintenanceIndiv.petrolPrice[carComparison]
              ? formatPrice(
                  allCosts.maintenanceIndiv.petrolPrice[carComparison],
                )
              : 'N.v.t.',
          variant: 'black',
          icon: Diesel,
        },
        {
          text:
            answers.financing === 'operational'
              ? 'Reeds verwerkt in leasebedrag'
              : allCosts.maintenanceIndiv.evPrice[carComparison]
              ? formatPrice(allCosts.maintenanceIndiv.evPrice[carComparison])
              : 'N.v.t.',
          variant: 'black',
          icon: Lightning,
        },
      ],
    },
    {
      title: {
        text: 'Brandstof vs. Energie',
        variant: 'black',
        subtitle: `Op basis van ingevulde km: ${
          answers.range && answers.range[carComparison]
        }`,
      },
      items: [
        {
          text: formatPrice(
            allCosts.variable.rangeAmount.petrol[carComparison],
          ),
          variant: 'black',
          icon: Diesel,
        },
        {
          text: formatPrice(allCosts.variable.rangeAmount.ev[carComparison]),
          variant: 'black',
          icon: Lightning,
        },
      ],
    },
    {
      title: {
        text: `Totaal ${timeCopy}`,
        variant: 'black',
        weight: 700,
      },
      items: [
        {
          text: formatPrice(allCosts.totalIndiv.petrolPrice[carComparison]),
          variant: 'black',
          weight: 700,
          icon: Diesel,
        },
        {
          text: formatPrice(allCosts.totalIndiv.evPrice[carComparison]),
          variant: 'orange',
          icon: Lightning,
          weight: 700,
        },
      ],
    },
  ];

  if (
    carComparison.startsWith('van') &&
    answers.financing !== 'operational' &&
    answers.userType === 'business'
  ) {
    carData.splice(0, 0, miaTaxBenefit);
  }
  if (answers.financing === 'self' || answers.financing === 'unknown') {
    carData.splice(0, 0, carPrice);
  } else {
    carData.splice(0, 0, leasePrice);
  }

  return carData;
};
